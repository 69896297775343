<template>
  <v-card class="card-shadow mb-6" :class="bgColor">
    <div class="card-header-padding text-center card-border-bottom">
      <h4
        class="text-h4 font-weight-600 text-uppercase py-4"
        :class="{ 'text-white': hasBg, 'text-primary': plainBg }"
      >
        Bravo Pack
      </h4>
    </div>
    <v-card-text class="card-padding text-center card-border-bottom">
      <div
        class="display-h-2 font-weight-600"
        :class="{ 'text-white': hasBg, 'text-typo': plainBg }"
      >
        $49
      </div>
      <span
        class="font-size-root"
        :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
        >per application</span
      >

      <v-list class="py-6 mx-auto" color="transparent" width="325">
        <v-list-item
          v-for="item in features"
          :key="item.title"
          class="px-0 py-0"
        >
          <v-list-item-avatar
            :size="24"
            color="#fff"
            class="my-0 me-2"
            :class="{ 'bg-gradient-primary': plainBg }"
          >
            <v-icon
              size="9"
              :class="{ 'text-white': plainBg, 'text-muted': hasBg }"
              >{{ item.icon }}</v-icon
            >
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
            <v-list-item-title
              v-text="item.title"
              class="text-body-2 ls-0"
              :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
        color="#5e72e4"
        >Start Free Trial</v-btn
      >
    </v-card-text>
    <div class="card-header-padding text-center">
      <a
        href="javascript:;"
        class="text-decoration-none text-body-2 ls-0 font-size-root no-default-hover"
        :class="{ 'text-white': hasBg, 'text-muted': plainBg }"
        target="_blank"
        >Request a demo</a
      >
    </div>
  </v-card>
</template>
<script>
export default {
  name: "card-pricing",
  props: {
    bgColor: String,
  },
  data() {
    return {
      hasBg: this.bgColor != undefined ? true : false,
      plainBg: this.bgColor === undefined ? true : false,
      features: [
        {
          icon: "fas fa-terminal",
          title: "Complete documentation",
        },
        {
          icon: "fas fa-pen-fancy",
          title: "Working materials in Sketch",
        },
        {
          icon: "fas fa-hdd",
          title: "2GB cloud storage",
        },
      ],
    };
  },
};
</script>
